<template>
  <div>
    <v-card
      :loading="carregandoBuscar || carregandoPermissoes"
      elevation="1"
    >
      <v-card-title class="grey lighten-3 text-h4">
        # {{ grupo.id || '--' }}
      </v-card-title>
      <v-divider />
      <validation-observer ref="observer">
        <v-form class="mt-4">
          <v-container>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <validation-provider
                  v-slot="{ errors }"
                  vid="name"
                  name="Nome"
                >
                  <v-text-field
                    v-model="grupoNovo.name"
                    outlined
                    dense
                    hide-details="auto"
                    :disabled="carregandoBuscar"
                    label="Nome"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
            </v-row>

            <v-row class="mt-7">
              <v-col
                v-for="(permissao) in listagemPermissoes"
                :key="permissao.id"
                cols="12"
                md="3"
                class="py-1 col-md-6 col-lg-4 col-xl-3"
              >
                <v-card
                  class="card-permissoes"
                >
                  <p class="titulo-permissoes">{{ formatacao(permissao.titulos) }}
                  </p>
                  <v-checkbox
                    v-for="(value) in permissao.permissoes"
                    :key="value.id"
                    v-model="permissoesSelecionadas"
                    class="pa-0 ma-0"
                    :value="value"
                    :label="value"
                    :disabled="carregandoBuscar"
                    hide-details
                  />
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </validation-observer>
      <v-divider class="mt-4" />
      <v-card-actions>
        <v-row>
          <v-col
            cols="12"
            class="text-left"
          >
            <btn-salvar
              :carregando="carregandoSalvar"
              @click="salvar"
            />
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
  import _ from 'lodash'
  import permissoes from '@/api/permissoes'

  export default {
    name: 'GruposCampos',

    props: {
      validacaoFormulario: {
        type: Object,
        default: () => { return {} },
      },
      carregandoSalvar: {
        type: Boolean,
        default: false,
      },
      carregandoBuscar: {
        type: Boolean,
        default: false,
      },
      grupo: {
        type: Object,
        default: () => { return {} },
      },
      permissoes: {
        type: Array,
        default: () => { return [] },
      },
    },

    data () {
      return {
        carregandoPermissoes: false,
        listagemPermissoes: [],
        permissoesSelecionadas: [],
        grupoNovo: {
          name: null,
        },
      }
    },

    watch: {
      validacaoFormulario (val) {
        if (!val) return

        return this.$refs.observer.setErrors(val)
      },

      grupo (grupo) {
        if (_.isEmpty(grupo)) return false

        this.grupoNovo = grupo
      },

      permissoes (permissoes) {
        if (_.isEmpty(permissoes)) return false

        this.permissoesSelecionadas = Object.values(Object.keys(permissoes).map(key => permissoes[key].name))
      },

    },

    mounted () {
      this.buscarPermissoes()
    },

    methods: {
      formatacao (item) {
        const objeto = item.replaceAll(/_|-/g, ' ').toString()
        return objeto
      },
      async buscarPermissoes () {
        try {
          this.carregandoPermissoes = true

          const resposta = await permissoes.listar()

          // const permissoesTotals = []

          resposta.data.map((item) => {
            const permissoesBloco =
              { 
                titulos: null,
                permissoes: [],
              }
            for (var key in item) {
              permissoesBloco.titulos = key
              item[key].map((item) => {
                permissoesBloco.permissoes.push(item)
              })
            } 
            this.listagemPermissoes.push(permissoesBloco)
          })
        } catch (e) {
          this.$router.push('/grupos', () => this.$snackbar.mostrar({ cor: 'error', mensagem: this.$erroApi._(e) }))
        } finally {
          this.carregandoPermissoes = false
        }
      },

      salvar () {
        this.$emit('salvar', this.grupoNovo, this.permissoesSelecionadas)
      },
    },

  }
</script>
<style scoped>
.titulo-permissoes {
  text-transform: capitalize;
  font-weight: bold;
}
.card-permissoes {
  min-height: 300px;
  padding: 1em
}
</style>
